import React from "react";
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"

/**
 * Components
 */
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';
import ListCheck from '@components/list-check/list-check';
import Relative from '@components/relative/relative';
import Holder from '@components/holder/holder';
import CalendlyCTAOne from '@components/calendly/global-cta-one';

const PropertyControl = (props,{className}) => {

  if(props.CTA_URL != null) {
    if ((props.CTA_URL.match('http')) || (props.CTA_URL.match('https'))) {
      var Link_Type = "external"
    }
    else {
      var Link_Type = "internal"
    }
  }


  return (
    <Relative className="property-control">
        <Container className="overflow-hidden">
          <Row className="register">
            <Col
              className="align-self-center text-box order-2 order-lg-2 text-md-center text-lg-left"
              lg={6}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4>{props.Title}</h4>
              <p>
                <ReactMarkdown source={props.Content} className="property-control"/>
              </p>
              {props.CTA_URL !== null && props.CTA_URL !== "#calendly" &&
              <Holder maxWidth="260px" mobile={false} className="mx-sm-auto mx-lg-0">
                {Link_Type == "external" &&
                  <a href={props.CTA_URL} target="_blank" rel="nofollow" className="btn btn-primary">{props.CTA_Label}</a>
                }
                {Link_Type == "internal" &&
                  <Link to={`/${props.CTA_URL}`} className="primary-button btn btn-primary">{props.CTA_Label}</Link>
                }
              </Holder>
              }


              {props.CTA_URL !== null && props.CTA_URL === "#calendly" &&
                <Holder maxWidth="271px" mobile={false}>
                  <CalendlyCTAOne url={props.Calendly}/>
                </Holder>
              }
            </Col>
            <Col
              lg={6}
              className="order-lg-2 order-1"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
            >

          {props.Upload_Video != null && props.Upload_Video != "#" &&
            <Video
                width="45.625%"
                height="600px"
                mobileHeight="153vw"
                align="right"
                imageContain={true}
                contain={true}
              image={props.Image}
              video={props.Upload_Video.publicURL}
            />
              }
              {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
                <ModalVideo
                width="45.625%"
                height="600px"
                mobileHeight="153vw"
                align="right"
                imageContain={true}
                contain={true}
                  video={props.Embed_Video_URL}
                  name={props.Title}
                />
              }
              {props.Upload_Video == null && props.Embed_Video_URL == null &&
                <NoVideo
                width="45.625%"
                height="600px"
                mobileHeight="153vw"
                align="right"
                imageContain={true}
                contain={true}
                  image={props.Image}
                />
              }

            </Col>
          </Row>
        </Container>
      </Relative>
  )
}

export default PropertyControl
